.container {
  --loader-margin-size: 6.25rem;
  --loader-font-size: 1.5rem;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
  padding: calc(50vh - var(--loader-margin-size) - var(--loader-font-size) / 2) 0 0;
}

.loader {
  --spinner-rgb-values: 255, 255, 255;
  --spinner-color: rgb(var(--spinner-rgb-values));

  margin: 6.25rem auto;
  font-size: 1.5rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0 -2.6em 0 0 var(--spinner-color), 1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      2.5em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.75em 1.75em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      0 2.5em 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em 1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      -2.6em 0 0 0 rgba(var(--spinner-rgb-values), 0.5), -1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.7);
  }
  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(var(--spinner-rgb-values), 0.7), 1.8em -1.8em 0 0 var(--spinner-color),
      2.5em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.75em 1.75em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      0 2.5em 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em 1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      -2.6em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.5);
  }
  25% {
    box-shadow: 0 -2.6em 0 0 rgba(var(--spinner-rgb-values), 0.5), 1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.7),
      2.5em 0 0 0 var(--spinner-color), 1.75em 1.75em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      0 2.5em 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em 1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      -2.6em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2);
  }
  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.5),
      2.5em 0 0 0 rgba(var(--spinner-rgb-values), 0.7), 1.75em 1.75em 0 0 var(--spinner-color),
      0 2.5em 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em 1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      -2.6em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2);
  }
  50% {
    box-shadow: 0 -2.6em 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      2.5em 0 0 0 rgba(var(--spinner-rgb-values), 0.5), 1.75em 1.75em 0 0 rgba(var(--spinner-rgb-values), 0.7),
      0 2.5em 0 0 var(--spinner-color), -1.8em 1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      -2.6em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2);
  }
  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      2.5em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.75em 1.75em 0 0 rgba(var(--spinner-rgb-values), 0.5),
      0 2.5em 0 0 rgba(var(--spinner-rgb-values), 0.7), -1.8em 1.8em 0 0 var(--spinner-color),
      -2.6em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2);
  }
  75% {
    box-shadow: 0 -2.6em 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      2.5em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.75em 1.75em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      0 2.5em 0 0 rgba(var(--spinner-rgb-values), 0.5), -1.8em 1.8em 0 0 rgba(var(--spinner-rgb-values), 0.7),
      -2.6em 0 0 0 var(--spinner-color), -1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2);
  }
  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.8em -1.8em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      2.5em 0 0 0 rgba(var(--spinner-rgb-values), 0.2), 1.75em 1.75em 0 0 rgba(var(--spinner-rgb-values), 0.2),
      0 2.5em 0 0 rgba(var(--spinner-rgb-values), 0.2), -1.8em 1.8em 0 0 rgba(var(--spinner-rgb-values), 0.5),
      -2.6em 0 0 0 rgba(var(--spinner-rgb-values), 0.7), -1.8em -1.8em 0 0 var(--spinner-color);
  }
}

.text {
  margin: 0;
  color: var(--layers-primaryBase);
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
