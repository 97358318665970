/* @import './styles.scss'; */

.layout {
  margin: 40px auto 0;
  width: 82%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalContainer::-webkit-scrollbar {
  display: none;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.hpWidth {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  gap: 2rem;
  padding-bottom: 172px;
}

.customContainer {
  margin-top: 1.5rem;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 1.5rem;
  width: 100%;
  max-width: 54.5rem;
  background: var(--layers-surface);
}

.listContainer,
.ordersScrollContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 950px) {
  .menu {
    display: none;
  }

  .modal {
    width: calc(100% - 2rem);
    height: 14.875rem;
    margin-right: 1rem;
  }

  .hpWidth {
    max-width: 100%;
    margin: 0;
    padding-bottom: 0;
  }

  .layout {
    width: 100%;
    margin-top: 0;
  }

  .infoContainer {
    background: var(--layers-surface); /* Ensure background is present */
  }
}
