@import '@desquared/rad-system-react/dist/styles.css';
@import '@desquared/wave-components-library/dist/styles.css';

body {
  color: rgb(var(--foreground-rgb));
  background-color: #f9f9f9;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  box-sizing: content-box;
  overflow-x: hidden;
}
